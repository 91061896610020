// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-jsx": () => import("./../../../src/pages/admin.jsx" /* webpackChunkName: "component---src-pages-admin-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-category-category-page-jsx": () => import("./../../../src/templates/category/category-page.jsx" /* webpackChunkName: "component---src-templates-category-category-page-jsx" */)
}

